import "rodal/lib/rodal.css"
import "./ProjectsGrid.css"
import "./Parameters.css"

import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { Button, Form, Nav, Navbar } from "react-bootstrap"
import { Eye } from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import Rodal from "rodal"

import {
  getProjects,
  updateCurrentProject,
  uploadProject,
} from "../actions/projectSlice"
import FloatingButtonToTop from "../components/FloatingButtonToTop"
import { isAuth } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"
import EmailHolder from "./EmailHolder"

const selectJson = [
  { id: 1, label: 24, value: 24 },
  { id: 3, label: 50, value: 50 },
]

const ProjectsGrid = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const initialPid = searchParams.get('pid')
  const projects = useSelector((state) => state.project.projects)
  const dispatch = useDispatch()
  const [viewNewWindow, setNewWindow] = useState(false)
  const [newName, setNewName] = useState("")
  const [newDescription, setNewDescription] = useState("")
  const [coverFile, setCoverFile] = useState(null)
  const [accessibility, setAccessibility] = useState("Private")
  const [filterValue, setFilterValue] = useState({})
  const author = isAuth().email
  const [users, setUsers] = useState([author])
  const [searchString, setSearchString] = useState("")
  const [projectsPerPage, setProjectsPerPage] = useState(selectJson[0].value)
  const [currentPage, setCurrentPage] = useState(1)
  const projectList = Object.values(projects)
  let pageSize = parseInt(
    Math.ceil(Object.keys(projects).length / projectsPerPage),
    10,
  )
  const [res, setRes] = useState(
    projectList.slice(
      (currentPage - 1) * projectsPerPage,
      currentPage * projectsPerPage,
    ),
  )
  const reviewImage_ref = useRef(null)

  useEffect(() => {
    if(!initialPid) return 

    dispatch(updateCurrentProject(initialPid))
    navigate('/projects', { replace: true })
  }, [dispatch, initialPid])

  useEffect(() => {
    const result1 = projectList.slice(
      (currentPage - 1) * projectsPerPage,
      currentPage * projectsPerPage,
    )
    setRes(result1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsPerPage, currentPage, JSON.stringify(projectList)])

  const resetNewProject = () => {
    setNewName("")
    setNewDescription("")
    setNewWindow(false)
    setCoverFile(null)
  }

  const submitNewProject = () => {
    const ignoreCharacterProjectName = ["#", "%", "\\", "?", "/"]

    let sanitizedNewName = newName
      .split("")
      .filter((char) => !ignoreCharacterProjectName.includes(char))
      .join("")

    // Check values
    if (sanitizedNewName.length < 3) {
      toast.error("Project Name should be larger than 3")
      return
    }

    if (!accessibility) {
      toast.error("Project accessibility should be selected")
      return
    }
    if (users.length < 1) {
      toast.error("Users can't be empty")
      setUsers([author])
      return
    }
    if (!users.includes(author)) {
      toast.error("Not possible to remove Author")
      setUsers([author])
      return
    }

    if (coverFile !== null) {
      if (coverFile.type !== "image/png" && coverFile.type !== "image/jpeg") {
        toast.error("Cover Image should be PNG/JPEG/JPG")
        return
      }
    }

    const data = new FormData()
    data.append("name", sanitizedNewName)
    data.append("description", newDescription)
    data.append("author", author)
    data.append("accessibility", accessibility)
    data.append("company", isAuth().company)
    data.append("users", users)
    if (coverFile !== null) {
      data.append("file", coverFile)
      data.append("coverFile", coverFile.name)
    } else data.append("coverFile", "")

    let payload = {
      data,
    }
    dispatch(uploadProject(payload))
    resetNewProject()
  }

  const changeCover = (e) => {
    const _file = e.currentTarget.files[0]
    setCoverFile(_file)
    if (reviewImage_ref.current && _file) {
      const src = URL.createObjectURL(_file)
      reviewImage_ref.current.src = src
      reviewImage_ref.current.onload = function () {
        URL.revokeObjectURL(src)
      }
    }
  }

  const getCustomProjects = (search) => {
    setFilterValue(search)
    dispatch(getProjects(search))
  }

  const onSearch = (e) => {
    e.preventDefault()
    dispatch(getProjects({ search: searchString }))
  }

  const handleOnChange = ({ target: { value } }) => {
    setAccessibility(value)
  }

  const handleSelect = (e) => {
    setProjectsPerPage(e.target.value)
    setCurrentPage(1)
    pageSize = parseInt(
      Math.ceil(Object.keys(projects).length / projectsPerPage),
      10,
    )
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <div className="projects-container containerApp">
      <Navbar className="projects-bar" expand="lg">
        <Navbar.Text className="project-title"> Projects </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto my-1">
            <Button
              active={!Object.values(filterValue).length}
              className="filterBtn"
              onClick={() => {
                getCustomProjects({})
              }}
              variant="outline-primary"
            >
              {" "}
              All{" "}
            </Button>
            <Button
              active={Object.values(filterValue).includes("Public")}
              className="filterBtn"
              onClick={() => {
                getCustomProjects({ accessibility: "Public" })
              }}
              variant="outline-primary"
            >
              {" "}
              Public{" "}
            </Button>
            <Button
              active={Object.values(filterValue).includes("Private")}
              className="filterBtn"
              onClick={() => {
                getCustomProjects({ accessibility: "Private" })
              }}
              variant="outline-primary"
            >
              {" "}
              Private{" "}
            </Button>
            <Button
              active={Object.values(filterValue).includes("Company Public")}
              className="filterBtn"
              onClick={() => {
                getCustomProjects({ accessibility: "Company Public" })
              }}
              variant="outline-primary"
            >
              {" "}
              Company Public{" "}
            </Button>
            <Form className="project-search" onSubmit={onSearch}>
              <TextField
                className="project-search-text"
                id="outlined-size-small"
                onChange={(e) => {
                  setSearchString(e.target.value)
                }}
                onFocus={(e) => {
                  e.target.select()
                }}
                placeholder="Filter"
                size="small"
                type="text"
              />
              <Button className="my-auto" type="submit" variant="primary">
                {" "}
                Search{" "}
              </Button>
            </Form>
          </Nav>
          <Nav className="mr-sm-2 ml-auto">
            <Button
              className="my-auto btn-new-project"
              onClick={() => {
                setNewWindow(true)
              }}
            >
              {" "}
              New Project{" "}
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className="grid-container">
        {res.map((item) => (
          <div
            // eslint-disable-next-line react/no-unknown-property
            before-content={item.description}
            className="card"
            id={item.pid}
            key={item.pid}
            onClick={(e) => {
              if (e.target.className === "card-author-name") {
                ConsoleHelper("author click ", e.target.id)
              } else {
                dispatch(updateCurrentProject(e.target.id))
              }
            }}
          >
            <div className="card-img-holder" id={item.pid}>
              <img
                alt="Cover"
                className="card-img"
                id={item.pid}
                src={
                  item.coverFile !== ""
                    ? process.env.REACT_APP_API_URL +
                      "/backend/" +
                      author +
                      "/" +
                      item.pid +
                      "/" +
                      item.coverFile
                    : require("../images/noImage.png")
                }
              />
            </div>
            <div className="card-body" id={item.pid}>
              <h1 className="card-title" id={item.pid}>
                {" "}
                {item.name}{" "}
              </h1>
              <div className="card-author" id={item.pid}>
                <div className="card-author-name" id={item.author}>
                  {" "}
                  {item.author}{" "}
                </div>
              </div>
            </div>
            <div
              className="card-stats d-flex flex-row-reverse align-items-center"
              id={item.pid}
            >
              <div className="d-flex p-2 align-items-center">
                <Eye size={13} />
                <div className="stats">{item.views}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <Pagination
          count={pageSize}
          onChange={handlePageChange}
          page={currentPage}
          size="large"
          sx={{ float: "right", textAlign: "center" }}
        />
        <FormControl
          size="small"
          sx={{
            m: 1,
            minWidth: 120,
            float: "right",
            paddingLeft: "20px",
            minHeight: "30px",
          }}
          variant="standard"
        >
          <Select
            id="demo-simple-select-filled"
            labelId="demo-simple-select-filled-label"
            onChange={handleSelect}
            value={projectsPerPage}
          >
            {selectJson.map((item) => (
              <MenuItem key={item.id} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Rodal
        className="newWindow"
        customStyles={{ height: "auto", bottom: "auto" }}
        onClose={resetNewProject}
        visible={viewNewWindow}
      >
        <h1 className="title title-new-project"> New Project </h1>
        <div className="container">
          <hr />
          <div className="parameter">
            <input
              className="name"
              onChange={(e) => setNewName(e.target.value)}
              placeholder=" Name"
              type="text"
              value={newName}
            />
            <textarea
              className="description"
              onChange={(e) => setNewDescription(e.target.value)}
              placeholder=" Description "
              type="text"
              value={newDescription}
            />
            <div className="imageReviewWrapper" hidden={!coverFile}>
              <img
                alt="Picture review"
                ref={reviewImage_ref}
                src={require("../images/noImage.png")}
              />
            </div>

            <div className="custom-file">
              <input
                className="custom-file-input"
                id="inputGroupFile01"
                onChange={changeCover}
                type="file"
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                {" "}
                {coverFile == null ? "Cover Picture" : coverFile.name}{" "}
              </label>
            </div>
            <div className="custom-users">
              <EmailHolder setUsers={setUsers} users={users} />
            </div>
            <div className="select-wrapper">
              <div className="select-title">Accessibility</div>
              <div className="select">
                <select onChange={handleOnChange} value={accessibility}>
                  <option value="Private">Private</option>
                  <option value="Public">Public</option>
                  <option value="Company Public">Company Public</option>
                </select>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="button-holder">
          <Button
            className="button button-ok"
            onClick={submitNewProject}
            variant="primary"
          >
            {" "}
            OK{" "}
          </Button>
          <Button
            className="button"
            onClick={resetNewProject}
            variant="secondary"
          >
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </Rodal>
      <FloatingButtonToTop />
    </div>
  )
}

export default ProjectsGrid
