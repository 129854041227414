import { useEffect, useMemo, useState } from "react"

export const usePrintImageSgan = ({ parameters, files }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (!files?.length) return

    const file = files[0]
    const img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height })
    }
  }, [files])

  const wFocuKernelInterpolates = useMemo(() => {
    if (!parameters.kernel_size || !parameters.interpolate || !imageSize.width)
      return 0

    return (
      (parameters.kernel_size / parameters.interpolate / imageSize.width) * 100
    )
  }, [parameters, imageSize])

  return {
    wFocuKernelInterpolates,
  }
}
